import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title?: string;
  description?: string;
  path?: string;
}

export function SEO({ title, description, path = '' }: SEOProps) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  
  const defaultTitle = t('appName');
  const defaultDescription = t('description');
  const fullTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;
  
  const baseUrl = 'https://translate.suan1gua.com'; // Update this with your actual domain
  const canonicalUrl = `${baseUrl}${path}`;
  
  const supportedLanguages = ['en', 'zh', 'ja', 'ko', 'es', 'pt', 'ru', 'ar'];
  
  return (
    <Helmet>
      {/* Basic meta tags */}
      <html lang={currentLanguage} />
      <title>{fullTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Open Graph meta tags */}
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={defaultTitle} />
      
      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
      
      {/* Alternate language links */}
      {supportedLanguages.map((lang) => (
        <link
          key={lang}
          rel="alternate"
          hrefLang={lang}
          href={`${baseUrl}${path}?lng=${lang}`}
        />
      ))}
      <link rel="alternate" hrefLang="x-default" href={baseUrl} />
      
      {/* Additional meta tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="Content-Language" content={currentLanguage} />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow" />
      
      {/* Keywords */}
      <meta name="keywords" content="translator, translation tool, language translator, online translator, multi language, Splash Translator" />
    </Helmet>
  );
}
