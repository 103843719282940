import { useState } from 'react'
import Select from 'react-select'
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { HelmetProvider } from 'react-helmet-async'
import { SEO } from './components/SEO'

const languages = [
  { value: 'en', label: 'English' },
  { value: 'zh', label: 'Chinese' },
  { value: 'es', label: 'Spanish' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ko', label: 'Korean' },
  { value: 'ru', label: 'Russian' },
]

// 从环境变量中获取 Worker URL
const WORKER_URL = import.meta.env.VITE_WORKER_URL

function LanguageSwitcher() {
  const { i18n } = useTranslation()
  const languages = [
    { value: 'en', label: 'English' },
    { value: 'zh', label: '中文' },
    { value: 'ja', label: '日本語' },
    { value: 'ko', label: '한국어' },
    { value: 'es', label: 'Español' },
    { value: 'pt', label: 'Português' },
    { value: 'ru', label: 'Русский' },
    { value: 'ar', label: 'العربية' }
  ]

  const currentLanguage = languages.find(lang => lang.value === i18n.language) || languages[0]

  return (
    <Select
      value={currentLanguage}
      onChange={(option) => option && i18n.changeLanguage(option.value)}
      options={languages}
      className="w-40"
      classNamePrefix="select"
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 })
      }}
    />
  )
}

function About() {
  const { t } = useTranslation()
  return (
    <>
      <SEO 
        title={t('about.title')}
        description={t('about.description')}
        path="/about"
      />
      <div className="min-h-screen w-screen bg-gray-100 dark:bg-gray-900">
        <div className="w-screen h-full p-8">
          <h1 className="text-3xl font-bold text-center mb-2 text-gray-800 dark:text-white">
            {t('about.title')}
          </h1>
          <div className="max-w-2xl mx-auto mt-8 bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg">
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              {t('about.description')}
            </p>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              {t('about.contact')}: <a href="mailto:contact@suan1gua.com" className="text-blue-600 dark:text-blue-400 hover:underline">contact@suan1gua.com</a>
            </p>
            <Link to="/" className="text-blue-600 dark:text-blue-400 hover:underline">
              {t('about.backToTranslator')}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

function Layout({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation()
  return (
    <div className="min-h-screen w-screen bg-gray-100 dark:bg-gray-900 pb-20">
      <nav className="bg-white dark:bg-gray-800 shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <Link to="/" className="flex items-center text-gray-800 dark:text-white hover:text-gray-600">
                {t('appName')}
              </Link>
            </div>
            <div className="flex items-center space-x-4">
              <LanguageSwitcher />
              <Link to="/about" className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-white">
                {t('about.title')}
              </Link>
            </div>
          </div>
        </div>
      </nav>
      {children}
      <div className="fixed bottom-0 w-full bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 p-4">
        <div className="max-w-7xl mx-auto">
          <a 
            href="https://amzn.to/4fg8bC8" 
            target="_blank" 
            rel="noopener noreferrer"
            className="block text-center text-blue-600 dark:text-blue-400 hover:underline"
          >
            {t('amazonAd')}
          </a>
        </div>
      </div>
    </div>
  )
}

function TranslatorApp() {
  const { t } = useTranslation()
  const [sourceText, setSourceText] = useState('')
  const [translatedText, setTranslatedText] = useState('')
  const [sourceLang, setSourceLang] = useState(languages[0])
  const [targetLang, setTargetLang] = useState(languages[1])
  const [isTranslating, setIsTranslating] = useState(false)
  const [error, setError] = useState('')

  const translate = async () => {
    if (!sourceText.trim()) return
    
    setIsTranslating(true)
    setError('')

    try {
      const response = await fetch(WORKER_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: sourceText,
          sourceLang: sourceLang.label,
          targetLang: targetLang.label,
        }),
      })

      const data = await response.json()
      
      if (!response.ok) {
        // 处理特定的错误状态码
        switch (response.status) {
          case 429:
            throw new Error('Rate limit exceeded. Please wait a moment before trying again.')
          case 500:
            throw new Error('Server error. Please try again later.')
          default:
            throw new Error(data.error || 'Translation failed')
        }
      }

      if (data.error) {
        throw new Error(data.error)
      }

      setTranslatedText(data.translatedText)
    } catch (error) {
      console.error('Translation error:', error)
      setError(error instanceof Error ? error.message : 'Translation failed. Please try again.')
      setTranslatedText('')
    } finally {
      setIsTranslating(false)
    }
  }

  return (
    <>
      <SEO path="/" />
      <div className="min-h-screen flex flex-col md:h-screen md:overflow-hidden">
        <div className="flex-none p-8 pb-4">
          <h1 className="text-3xl font-bold text-center mb-2 text-gray-800 dark:text-white">
            {t('appName')}
          </h1>
          <p className="text-center mb-4 text-gray-600 dark:text-gray-300">
            {t('description')}
          </p>
        </div>

        <div className="flex-1 px-8 pb-8 md:pb-4">
          <div className="h-full flex flex-col md:grid md:grid-cols-[1fr,auto,1fr] md:h-[calc(100vh-18rem)] gap-4">
            {/* Source Language */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg flex flex-col min-h-[35vh] h-full">
              <div className="flex-none mb-4 z-10">
                <Select
                  value={sourceLang}
                  onChange={(option) => setSourceLang(option!)}
                  options={languages}
                  className="text-gray-800"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                />
              </div>
              <textarea
                value={sourceText}
                onChange={(e) => setSourceText(e.target.value)}
                className="flex-1 w-full p-4 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:bg-gray-700 dark:text-white dark:border-gray-600 resize-none"
                placeholder={t('sourceTextPlaceholder')}
              />
            </div>

            {/* Mobile Translate Button */}
            <div className="md:hidden flex-none py-4">
              <button
                onClick={translate}
                disabled={isTranslating || !sourceText.trim()}
                className="w-full px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed shadow-md"
              >
                {isTranslating ? t('translating') : t('translateButton')}
                <span className="inline-block ml-1">↓</span>
              </button>
            </div>

            {/* Desktop Translate Button */}
            <div className="hidden md:flex items-center justify-center">
              <button
                onClick={translate}
                disabled={isTranslating || !sourceText.trim()}
                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed shadow-lg transform transition-transform hover:scale-105"
              >
                {isTranslating ? t('translating') : t('translateButton')}
                <span className="inline-block ml-2">→</span>
              </button>
            </div>

            {/* Target Language */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg flex flex-col min-h-[35vh] h-full">
              <div className="flex-none mb-4 z-10">
                <Select
                  value={targetLang}
                  onChange={(option) => setTargetLang(option!)}
                  options={languages}
                  className="text-gray-800"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                />
              </div>
              <textarea
                value={translatedText}
                readOnly
                className="flex-1 w-full p-4 border rounded-lg bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600 resize-none"
                placeholder={t('targetTextPlaceholder')}
              />
            </div>
          </div>
        </div>

        {error && (
          <div className="flex-none px-8 pb-4 text-center text-red-600 dark:text-red-400">
            {error}
          </div>
        )}
      </div>
    </>
  )
}

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout><TranslatorApp /></Layout>} />
          <Route path="/about" element={<Layout><About /></Layout>} />
        </Routes>
      </Router>
    </HelmetProvider>
  )
}

export default App
