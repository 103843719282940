import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en.json';
import zhTranslation from './locales/zh.json';
import jaTranslation from './locales/ja.json';
import koTranslation from './locales/ko.json';
import esTranslation from './locales/es.json';
import ptTranslation from './locales/pt.json';
import ruTranslation from './locales/ru.json';
import arTranslation from './locales/ar.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: enTranslation,
      zh: zhTranslation,
      ja: jaTranslation,
      ko: koTranslation,
      es: esTranslation,
      pt: ptTranslation,
      ru: ruTranslation,
      ar: arTranslation,
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
